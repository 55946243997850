<template>
  <div
    class="breadcrumb"
    aria-label="breadcrumbs"
  >
    <ul>
      <li><a @click="emit('page', 'start')">Inhoudsopgave</a></li>
      <li class="is-active">
        <a>Verantwoording</a>
      </li>
    </ul>
  </div>

  <h3 class="title is-3">
    Verantwoording
  </h3>
    

  <h4 class="title is-4">
    Doel en functie BHV-plan
  </h4>
  <p class="block">
    Doel van het Bedrijfshulpverleningsplan (BHV-plan) is om een BHV-organisatie in te richten, die in geval van een (dreigende) noodsituatie tijdig wordt geactiveerd en die de gevolgen van de noodsituatie zoveel mogelijk beperkt. 
  </p>
  <p class="block">
    Daarnaast heeft het BHV-plan een informatieve functie. Het maakt betrokkenen bewust van hun verantwoordelijkheden, processen, beschikbare middelen en mogelijke risico’s. 
  </p>
  <p class="block">
    Het BHV-plan bevat alle gegevens, die nodig zijn om in geval van een incident doelmatig op te optreden om letsel en schade te beperken. Het richt zich voornamelijk op de hulpverlening in het belang van de arbeidsveiligheid van medewerkers en andere personen. 
  </p>


  <h4 class="title is-4">
    Structuur BHV-plan
  </h4>
  <p class="block">
    De onderstaande documenten en middelen maken onderdeel uit van dit BHV-plan. Sommige documenten zijn op verschillende plaatsen in het pand te vinden (zie tabel in hoofdstuk 2). Alle documenten zijn ook beschikbaar via SharePoint KVGM.
  </p>

  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>Naam</th>
        <th>Beschikbaar via</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Noodkaart</td>
        <td>Op locatie en SharePoint -> <a href="https://rphwaalwijk.sharepoint.com/:b:/r/sites/KVGMPubliek/Shared%20Documents/BHV-Noodkaart%20-%20vs%201.0%20-%2007dec20%20C.pdf?csf=1&web=1&e=Y24ZTI">KVGM-Publiek</a></td>
      </tr>
      <tr>
        <td>Plattegronden</td>
        <td>Op locatie en SharePoint -> <a href="https://rphwaalwijk.sharepoint.com/sites/KVGM-Welker/Gedeelde%20documenten/Forms/AllItems.aspx?csf=1&web=1&e=Txc6ap&cid=6069c99d-3db6-4fd6-8a97-010ee051b986&FolderCTID=0x01200041C36AAE9B6AC14CB81907814D3EECB8&viewid=b5f98b48-5676-4d1b-8b79-0e09fb73ddec&id=/sites/KVGM-Welker/Gedeelde%20documenten/0.%20KVGM%202020%20-%20VCA/07.%20Noodsituaties/03.%20BHV%20Vluchtplannen">KVGM-Welker</a></td>
      </tr>
      <tr>
        <td>Brandblusvoorzieningen</td>
        <td>Zie plattegronden</td>
      </tr>
      <tr>
        <td>Eerste Hulpmiddelen</td>
        <td>Zie plattegronden</td>
      </tr>
      <tr>
        <td>Jaarlijkse oefenagenda</td>
        <td>SharePoint, <a href="https://rphwaalwijk.sharepoint.com/:x:/r/sites/KVGM-Welker/Gedeelde%20documenten/0.%20KVGM%202020%20-%20VCA/07.%20Noodsituaties/02.%20BHV%20Oefeningen/Planning.xlsx?d=w1c3c7a1be89b4eef9947e631e1014eb4&csf=1&web=1&e=ctA2lF">KVGM-Welker</a>.</td>
      </tr>
      <tr>
        <td>Verslag oefeningen</td>
        <td>SharePoint, <a href="https://rphwaalwijk.sharepoint.com/:x:/r/sites/KVGM-Welker/Gedeelde%20documenten/0.%20KVGM%202020%20-%20VCA/07.%20Noodsituaties/02.%20BHV%20Oefeningen/Planning.xlsx?d=w1c3c7a1be89b4eef9947e631e1014eb4&csf=1&web=1&e=ctA2lF">KVGM-Welker</a>.</td>
      </tr>
      <tr>
        <td>Veiligheidsbladen</td>
        <td>SharePoint, <a href="https://rphwaalwijk.sharepoint.com/:f:/r/sites/KVGM-Welker/Gedeelde%20documenten/0.%20KVGM%202020%20-%2014001/07.%20VIB_MSDS?csf=1&web=1&e=GgauDx">KVGM-Welker</a>.</td>
      </tr>
      <tr>
        <td>Werkplek Instructie Kaart</td>
        <td>SharePoint, <a href="https://rphwaalwijk.sharepoint.com/:f:/r/sites/KVGMPubliek/Shared%20Documents/8.%20Werk%20Instructie%20Kaarten%20-%20Gevaarlijke%20Stoffen?csf=1&web=1&e=OyS2Q1">KVGM-Publiek</a>.</td>
      </tr>
    </tbody>
  </table>

  <h4 class="title is-4">
    Taken BHV-organisatie
  </h4>
  <p class="block">
    De BHV-organisatie heeft de volgende taken
  </p>
  <div class="block content">
    <ul>
      <li>Verlenen van eerste hulp bij ongevallen</li>
      <li>Uitvoeren van eerste levensreddende handelingen d.m.v. reanimatie of AED</li>
      <li>Bestrijden van brand</li>
      <li>Ontruimen van het pand</li>
      <li>Samenwerken met externe hulpverleningsorganisaties</li>
      <li>Optreden in geval van een overval, agressie, geweld, milieucalamiteiten en het beperken van de gevolgen daarvan </li>
    </ul>
  </div>

  <h4 class="title is-4">
    Verantwoordelijkheden
  </h4>
  <p class="block">
    De werkgever is wettelijk verantwoordelijk voor de veiligheid van de werknemers en zorgt voor de bedrijfsmiddelen. Zij dient ervoor te zorgen dat de BHV-organisatie alle middelen heeft om haar taken uit te voeren. 
  </p>
  <p class="block">
    Van iedere werknemer wordt verwacht dat hij de veiligheidsregels naleeft en zijn verantwoordelijkheid neemt zodat het werk op een veilige manier wordt uitgevoerd. Werknemers spreken elkaar aan op onveilig gedrag.
  </p>

  <h4 class="title is-4">
    Beheer BHV-plan
  </h4>
  <p class="block">
    De KVGM-Coördinator evalueert het BHV-plan jaarlijks en stelt het indien nodig bij. Wijzigingen in de organisatie, de wet- en regelgeving, Risico Inventarisatie & Evaluatie (RI&E) en het Plan van Aanpak (PvA) worden door de KVGM-coördinator verwerkt in het BHV-plan. De KVGM-coördinator is verantwoordelijk voor het opstellen, invoeren, beheren en communiceren van het BHV-plan.
  </p>
    
  <h4 class="title is-4">
    Restrisico’s en de BHV
  </h4>
  <p class="block">
    Op grond van de RI&E worden de restrisico’s bepaald die leidend zijn bij het inrichten van de BHV-organisatie. Restrisico’s zijn minimale risico’s, die overblijven na een zorgvuldige uitvoering van de veiligheidsmaatregelen en voorzieningen. De negatieve gevolgen van deze restrisico’s worden door adequaat optreden tijdens een inzet van de BHV-organisatie zoveel mogelijk beperkt. 
  </p>
  <p class="block">
    De volgende scenario’s zijn uitgewerkt in dit BHV-plan
  </p>
  <div class="content">
    <ul>
      <li>Calamiteit met stikstof</li>
      <li>Brand</li>
      <li>Ongeval</li>
      <li>Milieucalamiteit</li>
      <li>Pandemie</li>
      <li>Overval</li>
      <li>Agressie en geweld</li>
      <li>Bommelding</li>
    </ul>
  </div>
</template>

<script setup>
const emit = defineEmits(['page'])
</script>

<style scoped>
img {
    width: 100%;
    max-height: 25vh;
    object-fit: contain;
}
</style>