<template>
  <div
    class="breadcrumb"
    aria-label="breadcrumbs"
  >
    <ul>
      <li><a @click="emit('page', 'start')">Inhoudsopgave</a></li>
      <li class="is-active">
        <a>Specifieke situaties</a>
      </li>
    </ul>
  </div>

  <h3 class="title is-3">
    Specifieke situaties
  </h3>

  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>Calamiteit</th>
        <th>Rol</th>
        <th>Taak</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Brand</td>
        <td>BHV-coördinator</td>
        <td>
          Taken verdelen<br>
          Blussen<br>
          Besluiten om (deels) te ontruimen
        </td>
      </tr>
      <tr>
        <td />
        <td>Ontruimer</td>
        <td>
          Toegewezen afdeling ontruimen
        </td>
      </tr>
      <tr>
        <td />
        <td>Melder</td>
        <td>
          112 bellen<br>
          Hulpdienst opwachten en informeren
        </td>
      </tr>
      <tr>
        <td>Ongeval</td>
        <td>BHV-coördinator</td>
        <td>
          Taken verdelen<br>
          EHBO verlenen
        </td>
      </tr>
      <tr>
        <td />
        <td>AED-er</td>
        <td>
          AED halen
        </td>
      </tr>
      <tr>
        <td />
        <td>Melder</td>
        <td>
          112 bellen<br>
          Hulpdienst opwachten en informeren
        </td>
      </tr>
      <tr>
        <td>Stikstoflek</td>
        <td>BHV-coördinator</td>
        <td>
          Verleen EHBO<br>
          Schakel stikstofinstallatie uit d.m.v. noodstop
        </td>
      </tr>
      <tr>
        <td />
        <td>Ontruimer</td>
        <td>
          Toegewezen afdeling ontruimen
        </td>
      </tr>
      <tr>
        <td />
        <td>Melder</td>
        <td>
          112 bellen<br>
          Hulpdienst opwachten en informeren
        </td>
      </tr>
      <tr>
        <td>Verdacht pakketje</td>
        <td>BHV-coördinator</td>
        <td>
          Taken verdelen<br>
          Eventueel EHBO verlenen
        </td>
      </tr>
      <tr>
        <td />
        <td>Ontruimer</td>
        <td>
          Toegewezen afdeling ontruimen
        </td>
      </tr>
      <tr>
        <td />
        <td>Melder</td>
        <td>
          112 bellen<br>
          Hulpdienst opwachten en informeren
        </td>
      </tr>
      <tr>
        <td>Overval</td>
        <td>BHV-coördinator</td>
        <td>
          Waarschuw 112
        </td>
      </tr>
      <tr>
        <td />
        <td>Overigen</td>
        <td>
          Blijf rustig<br>
          Luister naar overvaller<br>
          Noteer details
        </td>
      </tr>
      <tr>
        <td>Agressie</td>
        <td>BHV-coördinator</td>
        <td>
          Waarschuw 112<br>
          Probeer agressor tot rust te manen<br>
          Overweeg ontruiming
        </td>
      </tr>
      <tr>
        <td />
        <td>Overigen</td>
        <td>
          Blijf rustig<br>
          Noteer details
        </td>
      </tr>
      <tr>
        <td>Bommelding</td>
        <td>BHV-coördinator</td>
        <td>
          Waarschuw 112<br>
          Ontruim
        </td>
      </tr>
      <tr>
        <td />
        <td>Overigen</td>
        <td>
          Blijf rustig<br>
          Verlaat het pand
        </td>
      </tr>
    </tbody>
  </table>
    
  <h4 class="title is-4">
    Milieucalamiteit
  </h4>
  <p class="block">
    Mogelijke milieucalamiteiten bij RPH zijn
  </p>
  <div class="content block">
    <ul>
      <li>
        Morsen/lekkage conditioneringsmiddel
        <ul>
          <li>Bij morsen/lekkage van het conditioneringsmiddel in de werkplaats of magazijn zorgt de medewerker dat de lekkage stopt en ruimt het gemorste middel op. Afvoer van het conditioneringsmiddel via de betreffende inzamelaar</li>
          <li>Het conditioneringsmiddel wordt vervoerd in afgesloten cilinders (drukloos). De vulopeningen van de cilinders zijn afgeschermd met een plastic zakje om eventueel morsen te verhinderen</li>
          <li>Kleinschalige vervuiling in het spoor wordt handmatig verwijderd en afgevoerd middels vuilniszak in een emmer en vervolgens in Waalwijk aangeleverd voor verdere afvoer naar de betreffende inzamelaar</li>
          <li>Grotere hoeveelheden vervuiling worden gemeld aan de KVGM Coördinator en doorgegeven aan de opdrachtgever. In de regel bepaalt de opdrachtgever hoe verder gehandeld dient te worden.</li>
        </ul>
      </li>
    </ul>
  </div>

  <p class="block">
    Een milieucalamiteit wordt gerapporteerd aan de KVGM-coördinator. Afhankelijk van de aard en omvang van de milieucalamiteit bepaalt de KVGM-coördinator in overleg met de directie of deze aan het bevoegd gezag gemeld dient te worden. 
  </p>

  <p class="block">
    Onderstaande milieucalamiteiten worden altijd aan het bevoegd gezag gemeld (Omgevingsdienst Midden- en West-Brabant, telefoonnummer 013-2060 500, 24 uur per dag bereikbaar). 
  </p>
  <div class="content block">
    <ul>
      <li>Morsen en/of lekkage met als mogelijk gevolg bodemverontreiniging</li>
      <li>Morsen en/of lekkage milieuverontreinigende stoffen naar het riool </li>
      <li>Een calamiteit met mogelijke schade voor de directe omgeving </li>
    </ul>
  </div>

  <h4 class="title is-4">
    Pandemie (bijv. Covid 19)
  </h4>
  <p class="block">
    In geval van een pandemie volgt RPH altijd de instructies van de Rijksoverheid en het RIVM. 
  </p>
  <p class="block">
    Een Taak Risico Analyse (TRA) vormt het uitgangspunt om de risico’s en te nemen maatregelen bij een pandemie in kaart te brengen. Deze wordt ingevuld door het Management Team o.l.v. de KVGM-Coördinator.
  </p>
  <p class="block">
    De KVGM-Coördinator is het centraal aanspreekpunt voor vragen en communicatie m.b.t. de richtlijnen en uitvoering daarvan. De maatregelen worden op meerdere plaatsen in het bedrijf ter beschikking gesteld en via mail aan de medewerkers verspreid.
  </p>
  <p class="block">
    Het Covid 19-protocol omvat de volgende maatregelen:
  </p>
  <div class="content block">
    <ul>
      <li>Zoveel mogelijk vanuit thuis werken</li>
      <li>Medewerkers die niet vanuit thuis kunnen werken hanteren de 1,5 meter-regel en dragen een mondkapje waar dat niet kan</li>
      <li>Maximaal 1 medewerker per auto of kantoor</li>
      <li>Ook bij een klant houdt een medewerker van RPH zich minimaal aan de richtlijnen van het RIVM en RPH, ook als de klant minder strenge regels hanteert</li>
      <li>Medewerkers hebben allemaal de beschikking over desinfectiemiddelen en hebben hygiëne-instructies ontvangen m.b.t. het handen wassen</li>
      <li>Werkzaamheden in landen met een oranje reisadvies zijn geannuleerd</li>
    </ul>
  </div>


  <h4 class="title is-4">
    Thuiswerk-protocol
  </h4>
  <p class="block">
    Voor het thuiswerken geldt onderstaand protocol:
  </p>
  <div class="content block">
    <ul>
      <li>Medewerkers kunnen arbeidsmiddelen als computerscherm en bureaustoel tijdelijk mee naar huis nemen (melden bij Office Management)</li>
      <li>
        RPH zorgt voor onderlinge sociale betrokkenheid
        <ul>
          <li>Dagelijks starten de kantoormedewerkers samen de dag via video conferentie.</li>
          <li>Tweewekelijks is er een (vrijwillige) gezamenlijke lunch</li>
          <li>Iedere medewerker heeft de beschikking over digitale tools om eenvoudig visueel contact met zijn collegae te houden</li>
          <li>Regelmatig worden alle medewerkers getrakteerd op fruit of broodjes</li>
        </ul>
      </li>
      <li>Werk- en rusttijden zijn in basis gelijk aan het werk op kantoor in Waalwijk. Indien een specifieke thuissituatie een normale werkdag blokkeert kan een medewerker met zijn leidinggevende afspraken maken over afwijkende werktijden. </li>
      <li>RPH staat het niet toe dat medewerkers alleen op kantoor in Waalwijk werken. Als iemand thuis werkt is dit niet te voorkomen. Met digitale hulpmiddelen als Slack, Zoom en Teams houden medewerkers toch contact met elkaar.             </li>
    </ul>
  </div>
</template>

<script setup>
const emit = defineEmits(['page'])
</script>

<style scoped>
img {
    width: 100%;
    max-height: 25vh;
    object-fit: contain;
}
</style>