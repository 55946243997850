<template>
  <div
    class="breadcrumb"
    aria-label="breadcrumbs"
  >
    <ul>
      <li><a @click="emit('page', 'start')">Inhoudsopgave</a></li>
      <li class="is-active">
        <a>Basisgegevens</a>
      </li>
    </ul>
  </div>

  <h3 class="title is-3">
    Basisgegevens
  </h3>
    

  <h4 class="title is-4">
    Bedrijfsgegevens
  </h4>
  <table class="table is-fullwidth">
    <tbody>
      <tr>
        <td><strong>Naam bedrijf</strong></td>
        <td><strong>Rail Partner Holland B.V.</strong></td>
      </tr>
      <tr>
        <td>Hoofdadres</td>
        <td>Zinkerweg 13a, 5145 NL Waalwijk</td>
      </tr>
      <tr>
        <td>Telefoon</td>
        <td>0416-321515</td>
      </tr>
      <tr>
        <td>E-mail</td>
        <td>info@railpartnerholland.com</td>
      </tr>
      <tr>
        <td>Nevenvestiging</td>
        <td>Havenweg 7 5145 NJ Waalwijk</td>
      </tr>
    </tbody>
  </table>
  <p class="block">
    De hoofdingang van RPH bevindt zich aan de Zinkerweg 13a. In de panden Zinkerweg en Havenweg zijn in totaal maximaal circa 35 medewerkers werkzaam. Er is geen receptie aanwezig. Personeel kan het pand ook vanaf de Havenweg 7 te betreden. Via de achterzijde van beide panden en de buitenplaats kan het andere pand worden betreden. Beide panden hebben 1 verdieping. 
  </p>
  <p class="block">
    Bezoekers (gemiddeld 5 per week) betreden de panden alleen via de Zinkerweg 13a. Bezoekers zijn alleen onder begeleiding van een RPH-medewerker in de panden aanwezig. 
  </p>

  <h4 class="title is-4">
    Contactpersonen en telefoonnummers
  </h4>
  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>Funcie</th>
        <th>Naam</th>
        <th>Telefoon</th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td>Algemeen directeur</td>
        <td>M. van Gilst</td>
        <td>+31 (0) 614 284 995</td>
      </tr>
      <tr>
        <td>Operationeel Directeur</td>
        <td>M. Schipperheijn</td>
        <td>+31 (0) 686 805 663</td>
      </tr>
      <tr>
        <td>Manager Operations</td>
        <td>P. van der Mout</td>
        <td>+31 (0) 636 122 313</td>
      </tr>
      <tr>
        <td>Manager Sales-/marketing</td>
        <td>M. Bergkamp</td>
        <td>+31 (0) 615 561 571</td>
      </tr>
      <tr>
        <td>Manager ICT</td>
        <td>L. van der Linden</td>
        <td>+31 (0) 615 604 445</td>
      </tr>
      <tr>
        <td>KVGM-Coördinator</td>
        <td>H. Smits</td>
        <td>+31 (0) 651 167 558</td>
      </tr>
    </tbody>
  </table>

  <p class="block">
    Beveiligingsbedrijf Omgevingsdienst Midden- en West-Brabant<br>
    023 - 591 19 11 (24 uur per dag bereikbaar)<br>
    013 - 206 05 00 (24 uur per dag bereikbaar)
  </p>

  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>Soort/naam</th>
        <th>Adres</th>
        <th>Telefoon</th>
        <th>Opmerking</th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td colspan="4">
          <strong>Alarmnummer</strong>
        </td>
      </tr>
      <tr>
        <td>
          Politie<br>
          Ambulance<br>
          Brandweer
        </td>
        <td>--</td>
        <td>112</td>
        <td>Aanrijtijd circa 5 minuten</td>
      </tr>
      <tr>
        <td colspan="4">
          <strong>Huisartsen tijdens kantooruren</strong>
        </td>
      </tr>
      <tr>
        <td>Huisartsenpraktijk Verwielstraat</td>
        <td>
          Burg.Verwielstraat 33<br>
          5141 BD Waalwijk
        </td>
        <td>
          0416&#xa0;332&#xa0;331
        </td>
        <td />
      </tr>
      <tr>
        <td>Huisartsenpraktijk Kruispunt</td>
        <td>
          Meester van Coothstraat 49<br>
          5141 EP Waalwijk
        </td>
        <td>
          0416&#xa0;651&#xa0;650
        </td>
        <td />
      </tr>
      <tr>
        <td colspan="4">
          <strong>Huisarts buiten kantooruren</strong>
        </td>
      </tr>
      <tr>
        <td>Huisartsenpost</td>
        <td>
          Kasteellaan 2<br>
          5141 BM Waalwijk 
        </td>
        <td>
          0900&#xa0;332&#xa0;2222
        </td>
        <td>
          - Op werkdagen tussen 17:00 en 08:00 uur.<br>
          - In het weekend en op feestdagen de hele dag.<br> 
          - Eerst telefonisch contact opnemen
        </td>
      </tr>
    </tbody>
  </table>


  <h4 class="title is-4">
    Werktijden
  </h4>
  <p class="block">
    De werktijden op werkdagen zijn tussen 07.30 en 18.00 uur. In de avond tijdens werkdagen kunnen medewerkers ten behoeve van overwerk in het pand aanwezig zijn. Dit gebeurt niet frequent en specifieke tijden zijn hiervoor niet aan te geven. Gedurende de nacht en in het weekend is af en toe een medewerker (monteur) korte tijd aanwezig voor laden en lossen. 
  </p>

  <h4 class="title is-4">
    Beschikbaarheid en aanwezigheid
  </h4>
  <p class="block">
    In onderstaande tabel is per gebouw en verdieping aangegeven hoeveel medewerkers er doorgaans werkzaam zijn. Hierbij is geen rekening gehouden met incidentele aanwezigheid van de monteurs. Op aanwezigheidsborden naast de ingangen van beide panden is de actuele bezetting af te lezen. 
  </p>

  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>Pand/verdieping</th>
        <th>Werkplek</th>
        <th>Aantal medewerkers</th>
        <th>Minder of niet zelfredzame personen</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="4">
          <strong>Zinkerweg</strong>
        </td>
      </tr>
      <tr>
        <td>0</td>
        <td>Kantoor</td>
        <td>2</td>
        <td>0</td>
      </tr>
      <tr>
        <td>0</td>
        <td>Werkplaats</td>
        <td>Flexibel</td>
        <td>0</td>
      </tr>
      <tr>
        <td>1</td>
        <td>Kantoor</td>
        <td>4</td>
        <td>0</td>
      </tr>
      <tr>
        <td>Totaal</td>
        <td />
        <td>6</td>
        <td>0</td>
      </tr>
      <tr>
        <td colspan="4">
          <strong>Havenweg</strong>
        </td>
      </tr>
      <tr>
        <td>0</td>
        <td>Kantoor</td>
        <td>3</td>
        <td>0</td>
      </tr>
      <tr>
        <td>0</td>
        <td>Werkplaats/magazijn</td>
        <td>3</td>
        <td>0</td>
      </tr>
      <tr>
        <td>1</td>
        <td>Kantoor</td>
        <td>2</td>
        <td>0</td>
      </tr>
      <tr>
        <td>Totaal</td>
        <td />
        <td>8</td>
        <td>0</td>
      </tr>
    </tbody>
  </table>


  <h4 class="title is-4">
    Vluchtplantekeningen
  </h4>
  <p class="block">
    Op verschillende plaatsen in beide panden hangen vluchtplantekeningen. In bijlage 1 zijn de vluchtplantekeningen van RPH gegeven. 
  </p>
  <p class="block">
    De volgende vluchtplantekeningen zijn bij RPH aanwezig.
  </p>

  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>Locatie</th>
        <th>Datum</th>
        <th>Tekeningnummer</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="3">
          <strong>Zinkerweg</strong>
        </td>
      </tr>
      <tr>
        <td>Magazijn, nabij roldeur voor</td>
        <td>27-10-2020</td>
        <td>RPH VP1A_v271020.pdf</td>
      </tr>
      <tr>
        <td>Werkplaats</td>
        <td>27-10-2020</td>
        <td>RPH VP1B_v271020.pdf</td>
      </tr>
      <tr>
        <td>Kantoor, begane grond</td>
        <td>27-10-2020</td>
        <td>RPH VP1C_v271020.pdf</td>
      </tr>
      <tr>
        <td>Gang achteraan, eerste verdieping</td>
        <td>27-10-2020</td>
        <td>RPH VP2A_v271020.pdf</td>
      </tr>
      <tr>
        <td>Gang vooraan, eerste verdieping</td>
        <td>27-10-2020</td>
        <td>RPH VP2B_v271020.pdf</td>
      </tr>
      <tr>
        <td colspan="3">
          <strong>Havenweg</strong>
        </td>
      </tr>
      <tr>
        <td>Magazijn, nabij roldeur achter</td>
        <td>27-10-2020</td>
        <td>RPH VP3B_v271020.pdf</td>
      </tr>
      <tr>
        <td>Hal kantoor, begane grond</td>
        <td>27-10-2020</td>
        <td>RPH VP3C_v271020.pdf</td>
      </tr>
      <tr>
        <td>Hal kantoor, eerste verdieping</td>
        <td>27-10-2020</td>
        <td>RPH VP4A_v271020.pdf</td>
      </tr>
    </tbody>
  </table>

  <p class="block">
    Alle vluchtplannen vind je <a href="https://rphwaalwijk.sharepoint.com/:f:/r/sites/KVGMPubliek/Shared%20Documents/0.%20KVGM%202020%20-%20VCA/07.%20Noodsituaties/03.%20BHV%20Vluchtplannen?csf=1&web=1&e=VnYbLo">hier</a>.
  </p>

  <h4 class="title is-4">
    Vluchtwegen en nooduitgangen
  </h4>
  <p class="block">
    De nooduitgangen en vluchtwegen zijn gemarkeerd door bordjes met opschrift die verlicht zijn. In bijlage 1 zijn de nooduitgangen en vluchtwegen aangegeven. 
  </p>

  <h4 class="title is-4">
    Verzamelplaats
  </h4>
  <p class="block">
    In geval van een ontruiming van de Zinkerweg 13a en Havenweg 7 is de verzamelplaats in beide gevallen de grasstrook tegenover de ingang aan de Zinkerweg 13a. 
  </p>

  <h4 class="title is-4">
    Buren
  </h4>
  <p class="block">
    Beide gebouwen van RPH zijn op een hoek van een rij kantoorpanden gelegen. Onderstaand per gebouw de gegevens van het direct naastgelegen bedrijf. 
  </p>

  <p class="block">
    <strong>Gebouw Zinkerweg</strong><br>
    Dupal Bedrijfskleding<br>
    Zinkerweg 13b<br>
    5145 NL Waalwijk<br>
    0416 563 005<br>
  </p>
    
  <p class="block">
    <strong>Gebouw Havenweg</strong><br>
    Glaszettersbedrijf Nelis<br>
    Havenweg 9<br>
    5145 NJ Waalwijk<br>
    +31 6 1089 2819<br>
  </p>
</template>

<script setup>
const emit = defineEmits(['page'])
</script>

<style scoped>
img {
    width: 100%;
    max-height: 25vh;
    object-fit: contain;
}
</style>